<template>
  <div
    id="data-list-list-view"
    class="w-100"
  >
    <b-card 
      v-if="notifications && notifications.length === 0"
      class="p-3 w-100 mx-auto"
    >
      <h5
        class="mx-auto"
      >
        No new notifications
      </h5>
    </b-card>

    <div
      v-else
      class="pb-4"
    >
      <div class="d-flex w-100 align-items-center justify-content-between mb-2">
        <div>
          <ag-grid-select-page-size
            :pagination="pagination"
            @per-page-changed="pagination.perPage = $event"
          />
        </div>

        <div>
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            class="my-1 float-right mr-2"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>

      <div class="">
        <b-card
          v-for="notification in notifications"
          :key="notification.key"
          :class="{'bg-light-primary': !notification.opened}"
        >
          <div
            class="d-flex flex-wrap justify-content-between w-100"
          >
            <!-- <div class="mx-auto">
              <b-avatar
                v-if="notification.sender_details"
                size="64"
                :src="notification.sender_details.profile_image_url"
                :variant="'primary'"
              />
            </div> -->

              <!-- :style="isMobile ? 'max-width: 55vw;' : 'max-width: 65vw;'" -->
            <div
              class="w-100" 
            >
              <div class="d-flex justify-content-between">
                <h5 class="font-weight-bolder">
                  {{ notification.content.title }}
                </h5>

                <p>{{ notification.created_on }}</p>
              </div>
              <p>
                {{ notification.content.message }}
              </p>
            </div>
            <div
              class="mx-md-1 d-flex align-items-center w-100 mt-md-1"
              :class="{'w-100': isMobile}"
            >
              <b-button
                class="w-100"
                style="min-width: 12em;"
                :variant="notification.opened === true ? 'outline-warning' : 'outline-primary'"
                @click="clickedOnNotification(notification)"
              >
                {{ notification.notification_type === 'applications' ? 'View Application' : 'View Page' }}
              </b-button>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </div>

</template>

<script>
import { completionMixins } from '@/components/mixins/CompletionMixins'
import { notificationsMixins } from '@/views/notifications/mixins/notificationsMixins'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import AgGridSelectPageSize from '@/components/AgGridSelectPageSize.vue'

import DataViewSidebar from '@/layouts/components/DataViewSidebar.vue'
import {
  BCard,

} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    DataViewSidebar,

    AgGridSelectPageSize,
  },
  mixins: [completionMixins, notificationsMixins, onResizeMixins],
  data() {
    return {
      selected: [],
      itemsPerPage: 4,
      products: [],
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.notifications.length
    },
  },
  created() {
  },
  mounted() {
    // const incompleteProfile = this.checkProfileCompletion()

    // if (incompleteProfile) {
    //   this.popupProfileQuestions = true
    // }

    this.isMounted = true
  },
  methods: {
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    // getNotifications() {
    //   this.$http
    //     .get('/api/notifications')
    //     .then(response => {
    //       this.notifications = response.data.notifs
    //     })
    //     .catch(error => {
    //       this.$handleErrorResponse(error)
    //     })
    // },
  },
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
