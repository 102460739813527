/* eslint-disable import/prefer-default-export */

export const notificationsMixins = {
  data() {
    return {
      notifications: [],
      unreadNotificationsCount: 0,

      // server pagination
      previousFilters: {},
      filters: {},
      sorters: [],
      columns: ['_id', 'email', 'fullname', 'created_at', 'role', 'role_id', 'deactivated_at', 'account_approved',  'account_approved_text'],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 5,
      },

      serverParams: {},
      totalRecords: 0,
    }
  },
  watch: {
    '$route.name': function(newVal) {
      this.getNotifications()
    },

    'pagination.currentPage': function (val, oldVal) {
      this.getNotifications()
    },
    'pagination.perPage': function (val, oldVal) {
      this.getNotifications()
    },
  },
  created() {
    this.getNotifications()

    // this.populateMockData()
  },
  methods: {
    populateMockData() {
      this.notifications = [
        {
          opened: false,
          content: {
            title: 'System Maintenance',
            message: 'Our system will be down on Sun, 4th Jul 2023 23:00 for approximately 20 minutes.',
          },
          // avatar: require('@/assets/images/logo/dsg-transparent-logo.png'),
          // avatar: require('@/assets/images/logo/dsg-transparent-logo.jpg'),
          avatar: '',
          created_on: '4th Jul 2023 13:00',
        },
        {
          opened: true,
          content: {
            title: 'System Maintenance',
            message: 'Our system will be down on Sun, 28th May 2023 23:00 for approximately 20 minutes.',
          },
          // avatar: require('@/assets/images/logo/dsg-transparent-logo.png'),
          // avatar: require('@/assets/images/logo/dsg-transparent-logo.jpg'),
          avatar: '',
          created_on: '28th May 2023 13:00',
        },
        {
          opened: true,
          content: {
            title: 'System Maintenance',
            message: 'Our system will be down on Sun, 21th May 2023 23:00 for approximately 20 minutes.',
          },
          // avatar: require('@/assets/images/logo/dsg-transparent-logo.png'),
          // avatar: require('@/assets/images/logo/dsg-transparent-logo.jpg'),
          avatar: '',
          created_on: '21th May 2023 13:00',
        },
      ]
    },
    getNotifications() {
      // server pagination
      let query = ''
      this.serverParams.page = this.pagination.currentPage
      this.serverParams.perPage = this.pagination.perPage
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/notifications${query}`)
        .then(response => {
          const { success, output, total_records, unread_notifications_count } = response.data

          this.notifications = response.data.notifs
          this.totalRecords = total_records
          this.pagination.totalRows = total_records

          this.unreadNotificationsCount = unread_notifications_count
          // this.notifications.forEach(notification => {
          //   if (!notification.opened) {
          //     this.unreadNotificationsCount += 1
          //   }
          // })
        })
        .catch(() => {
          // this.$handleErrorResponse(error, 'An Error Occured', 'Could not retrieve notifications')
        })
    },

    readAllNotifications() {
      this.$http
        .put('/api/read-all-notifications', {})
        .then(response => {
          this.getNotifications()
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

    clickedOnNotification(notification) {
      this.$http
        .put(`/api/read-notification/${notification.id}`, {})
        .then(response => {
          this.getNotifications()

          this.$router.push(notification.content.link)
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

    goToNotificationsPage() {
      this.$router.push({ name: 'notifications' })
    },
  },
}